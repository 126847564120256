<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Hooke's Law</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      Hooke's law, named after British physicist Robert Hooke, is a fundamental principle in the area of mechanics of materials.
      Hooke's law relates the deformation of a spring to the force applied.
      Hooke, while experimenting with springs, found that the force required to stretch or compress a spring is linearly proportional to the extent of deformation.
      Mathematically, Hooke's law can be expressed as:
      $$ F \propto \delta $$
      where \(\delta = l - l_0\) is the stretching or compression of the spring (deformed length minus original length).
      Expressed as an equality (by introducing a proportionality constant \(k\)):
      $$ F = k (l- l_0)$$
      where \(F\) is the load applied, \(l\) is the deformed length and \(l_0\) is the original length of spring. The proportionality factor \(k\) is known as spring constant.

      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Learn Interactively | Hooke's Experiment</span>
      </div>
      <div ref="ib" style="margin-top: 20px" />
      This MagicGraph offers a visually interactive illustration of Hooke's experiment.
      This illustration shows a spring hanging from the ceiling at one end. At the other end of spring attached is a massless hanger to which weights can be added.
      <div ref="ic" style="margin-top: 20px" />
      <h5> To Get Started</h5>
      <div ref="id" style="margin-top: 20px" />
      Tap on the <i class="fa fa-plus-square" /> button to add weights and <i class="fa fa-minus-square" /> button to remove.
      As you add or remove weights, observe how the deformation of the spring changes with applied load.
    </div>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Use <i class="plus ma-1" /> button to add weights and <i class="minus ma-1" /> button to remove weights. Observe how the deformation of the spring changes with applied load. Use the slider to change the spring constant.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Springs',
  created: function () {
    // Store mutations
    let title = 'Hooke\'s Law';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Hooke\'s Law', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Implications of Hooke\'s law', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Hooke\'s Law',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
